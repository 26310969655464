import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker"

export { wrapRootElement } from "./gatsby-shared"

CheckWebpFeature()

export const onRouteUpdate = ({ location }) => {
  // if (location.pathname.split('?').length > 0) {
  //   window.history.replaceState(null, '', location.pathname);
  // }

  const params = new URLSearchParams(location.search)
  const _parameter1 = params.get("transType")

  // console.log("pathname", location.pathname)

  const defaultArea = process.env.GATSBY_DEFAULT_AREA
    ? process.env.GATSBY_DEFAULT_AREA
    : "London"
  const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g, "-")

  // Residential Redirects
  if (
    location.pathname === "/property/" ||
    location.pathname === "/property" ||
    location.pathname === "/property/for-sale/" ||
    location.pathname === "/property/for-sale"
  ) {
    window.location.replace(`/property/for-sale/in-${defaultArea_Slug}/`)
  }

  if (
    location.pathname === "/property/to-rent/" ||
    location.pathname === "/property/to-rent"
  ) {
    window.location.replace(`/property/to-rent/in-${defaultArea_Slug}/`)
  }

  // Commercial Redirects
  if (
    location.pathname === "/commercial-property/" ||
    location.pathname === "/commercial-property" ||
    location.pathname === "/commercial-property/for-sale/" ||
    location.pathname === "/commercial-property/for-sale"
  ) {
    window.location.replace(
      `/commercial-property/for-sale/in-${defaultArea_Slug}/`,
    )
  }

  if (
    location.pathname === "/commercial-property/to-rent/" ||
    location.pathname === "/commercial-property/to-rent"
  ) {
    window.location.replace(
      `/commercial-property/to-rent/in-${defaultArea_Slug}/`,
    )
  }

  // Newhomes Redirects
  if (
    location.pathname === "/newhomes/" ||
    location.pathname === "/newhomes" ||
    location.pathname === "/newhomes/for-sale/" ||
    location.pathname === "/newhomes/for-sale"
  ) {
    window.location.replace(`/newhomes/for-sale/in-${defaultArea_Slug}/`)
  }

  // console.log("old pathname", prevLocation?.search?.indexOf("utm_source"))
}
